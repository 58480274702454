<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style >
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
</style>

