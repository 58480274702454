import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import Vant from "vant";
import "element-ui/lib/theme-chalk/index.css";
import "vant/lib/index.css";
import "style/base.css";
import BaiduMap from "vue-baidu-map";
import axios from "axios";
Vue.prototype.axios = axios;
axios.defaults.baseURL = "https://b.einstein.top/";
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
Vue.prototype.getQueryValue = function (name) {
  var url = window.location.href;
  var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
  if (result == null || result.length < 1) {
    return "";
  }
  return decodeURI(result[1]);
};
Vue.config.productionTip = false;
Vue.use(BaiduMap, {
  ak: "kZ6TrpfDmHkCa6xEmG8FFRlkeWfUQY5k",
});
Vue.use(ElementUI);
Vue.use(Vant);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
