import Vue from "vue";
import VueRouter from "vue-router";
const index = () => import("../views/index.vue");
const about = () => import("../views/about.vue");
const cooperation = () => import("../views/cooperation.vue");
const school = () => import("../views/school.vue");
const preschool = () => import("../views/preschool.vue");
const strategy = () => import("../views/strategy.vue");
const news = () => import("../views/news.vue");
const details = () => import("../views/details.vue");
const application = () => import("../views/application.vue");
const infoImg = () => import("../views/infoImg.vue");
const h5 = () => import("../views/h5.vue");
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}; // 重复点击路由报错的问题
Vue.use(VueRouter);

const routes = [
  // { path: "/", redirect: '/' },
  {
    path: "/",
    name: "/",
    component: index, // 首页
  },
  {
    path: "/about",
    name: "/about",
    component: about,
  },
  {
    path: "/cooperation",
    name: "/cooperation",
    component: cooperation,
  },
  {
    path: "/school",
    name: "/school",
    component: school,
  },
  {
    path: "/preschool",
    name: "/preschool",
    component: preschool,
  },
  {
    path: "/strategy",
    name: "/strategy",
    component: strategy,
  },
  {
    path: "/news",
    name: "/news",
    component: news,
  },
  {
    path: "/details",
    name: "/details",
    component: details,
  },
  {
    path: "/application",
    name: "/application",
    component: application,
  },
  {
    path: "/infoimg",
    name: "/infoimg",
    component: infoImg,
  },
  {
    path: "/partner",
    name: "/partner",
    component: h5,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 };
  },
});
// router.beforeEach((to, from, next) => {

// })

export default router;
